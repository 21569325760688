<template>
  <div v-if="!$root.mainMenuHide">
    <Game />
  </div>
</template>

<script>
// @ is an alias to /src
import Game from "@/components/Game.vue";

export default {
  name: "Home",
  components: {
    Game
  }
};
</script>
